// src/Howicu.js
import React from 'react';

const Howicu = () => {
  return (
    <main style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: 'auto', 
      margin: '0 auto', 
      fontFamily: 'sans-serif', 
      color: '#170E0E', 
      backgroundColor: '#252525',
      padding: '16px',
      boxSizing: 'border-box'
    }}>
      {/* Main Content */}
      <div style={{ textAlign: 'center', color: 'white', marginBottom: '12px' }}>
        <h1 style={{ fontSize: '24px', fontWeight: '600', margin: '0' }}>Howicu</h1>
        <div style={{ fontSize: '18px', fontStyle: 'italic' }}>How We See Each Other</div>
      </div>
      
      <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <img src="/logo.svg" alt="Howicu Logo" style={{ width: '100%', height: 'auto', borderRadius: 16 }} />
      </div>
      
      <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <a href="https://apps.apple.com/us/app/howicu-how-we-see-each-other/id6736670343" target="_blank" rel="noopener noreferrer">
          <img src="/app-store-badge.svg" alt="Download on the App Store" style={{ width: '100%', height: 'auto' }} />
        </a>
      </div>
      
      {/* <div style={{ marginBottom: '12px', width: '100%', maxWidth: '256px' }}>
        <a href="https://play.google.com/store/apps/details?id=io.johntech.pumpa&hl=en" target="_blank" rel="noopener noreferrer">
          <img src="/google-play-badge.png" alt="Get it on Google Play" style={{ width: '256px', height: 'auto' }} />
        </a>
      </div> */}

      <div style={{ marginBottom: '24px', color: 'white', fontSize: '16px', textAlign: 'center' }}>
        Available on Google Play in November 2024.
      </div>

      {/* Social Links */}
      <div style={{ 
        width: '100%', 
        textAlign: 'center', 
        marginTop: '12px'
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          <a href="https://www.tiktok.com/@howicu" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>TikTok</a>
          <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>Privacy Policy</a>
          <a href="/support" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '14px', margin: '4px' }}>Support</a>
        </div>
      </div>

      {/* "More from me" Section */}
      <div style={{ 
        width: '100%', 
        maxWidth: '600px', 
        marginTop: '40px', 
        textAlign: 'center', 
        color: 'white', 
        borderTop: '1px solid #444', 
        paddingTop: '16px' 
      }}>
        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}>More from me</h2>
        
        <div style={{ marginBottom: '32px', width: '100%', maxWidth: '180px', margin: '0 auto' }}>
          <a href="https://pumpa.app" target="_blank" rel="noopener noreferrer">
            <img src="/elephant.svg" alt="Pumpa Logo" style={{ width: '100%', height: 'auto', borderRadius: 16 }} />
          </a>
        </div>

        <div style={{ color: 'white', fontSize: '16px' }}>
          Check out my workout tracking app - PUMPA
        </div>
      </div>
    </main>
  );
};

export default Howicu;
