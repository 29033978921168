// src/DeleteAccount.js
import React from 'react';
import { Link } from 'react-router-dom';

const DeleteAccount = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'sans-serif', color: '#170E0E', textAlign: 'left', backgroundColor: '#fcfcfc' }}>
      <h1>Delete Account</h1>
      {/* Back button at the top of the page */}
      <div style={{ marginBottom: '20px' }}>
        <Link to="/" style={{ textDecoration: 'none', color: '#007bff' }}>Back to Home</Link>
      </div>
      <p>If you would like to delete your account, please follow these steps within the app:</p>
      
      <ol>
        <li><strong>Log into your account:</strong> Ensure you are logged into your account through the app.</li>
        <li><strong>Click the "You" tab:</strong> Click on the "You" tab in the app's main navigation menu.</li>
        <li><strong>Click the "My Account" Button:</strong> In the "You" section, select the "My Account" button to access your account settings.</li>
        <li><strong>Click "Delete Account":</strong> Within your account settings, click the "Delete Account" option.</li>
        <li><strong>Provide your password to verify account deletion:</strong> You will be prompted to enter your password to confirm the deletion of your account. This is to ensure that only you can delete your account.</li>
      </ol>

      <p>Please note that deleting your account is irreversible and will result in the loss of all data associated with your account.</p>
    </div>
  );
}

export default DeleteAccount;